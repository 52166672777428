body {
  width: 100vw;
}

header {
  position: fixed;
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../Fonts/Bahnschrift.woff') format('woff'),
       url('../Fonts/BAHNSCHRIFT.TTF') format('truetype');
}

.contact-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  height: 100vh;
  width: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

.icind-contact-logo {
  margin-top: -70px;
  margin-bottom: 10px;
}

.contact-page h2 {
  max-width: 100%;
  font-size: 2rem;
  margin-top: 20px;
  color: #ffffff;
  text-align: center;
  font-family: 'MyWebFont', serif;
}

.contact-page p {
  max-width: 100%;
  font-size: 2rem;
  margin: 0;
  line-height: 1.5;
  text-align: center;
  color: #dddddd;
  font-family: 'MyWebFont', serif;
}

@media (max-width: 1000px){
  .contact-page p {
    max-width: 90%;
    font-size: 1.875rem;
    margin: 0;
    line-height: 1.5;
    text-align: center;
    color: #dddddd;
    font-family: 'MyWebFont', serif;
  }
}

@media (max-width: 47.9375rem) {
  .contact-page p {
    width: 100%;
  }
}
