body {
  overflow-x: hidden;
  position: relative;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../Fonts/Bahnschrift.woff') format('woff'),
       url('../Fonts/BAHNSCHRIFT.TTF') format('truetype');
}

.sevendays-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #000000;
  color: #ffffff;
  height: auto;
  width: auto;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

h1, h2, h3, h4, h5, p {
  font-family: 'MyWebFont', serif;
}

.game-background {
  position: fixed;
  opacity: 30%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.spotify-artist {
  background-color: transparent;
  padding: 10px;
  box-sizing: border-box;
}

.spotify-artist iframe {
  width: 100%;
  height: 31.25rem;
  border: none;
  max-width: 100%;
}

@media (max-width: 62.5rem){
  .spotify-artist iframe {
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 31.25rem;
  }
}

@media (max-width: 62.5rem) {
  .spotify-artist {
    padding: 5px;
  }
}

.our-discord {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  z-index: 1;
}

.discord-button {
  background-color: transparent;
  color: white;
  text-decoration: none;
  border: 2px solid green;
  margin-top: -50px;
  margin-bottom: 40px;
  padding: 20px;
  width: 6.25rem;
  text-align: center;
  animation: grow-shrink-2 1.5s linear infinite;
  z-index: 1;
}

.discord-button:hover {
  background-color: rgba(185, 219, 185, 0.175);
  animation: none;
}

@keyframes grow-shrink-2 {
  0% {
    transform: scale(1.0);
    opacity: 1;
  }
  11.11% {
    transform: scale(1.01);
    opacity: 1;
  }
  22.22% {
    transform: scale(1.02);
    opacity: 1;
  }
  33.33% {
    transform: scale(1.03);
    opacity: 1;
  }
  44.44% {
    transform: scale(1.04);
    opacity: 1;
  }
  55.55% {
    transform: scale(1.03);
    opacity: 1;
  }
  66.66% {
    transform: scale(1.02);
    opacity: 1;
  }
  77.77% {
    transform: scale(1.01);
    opacity: 1;
  }
  88.88% {
    transform: scale(1.0);
    opacity: 1;
  }
}

.sevendays-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 0;
}

.image-container {
  max-height: 28.125rem;
  max-width: auto;
  z-index: 1;
}

.topcontainer-gamedesc {
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
}

.topcontainer-gamedesc2 {
  margin: 10px;
  width: 70%;
  font-weight: 500;
  font-size: 1.3rem;
}

.sevendays-page h2 {
  font-size: 2rem;
  margin-top: 20px;
  color: #ffffff;
  z-index: -1;
}

.sevendays-page p {
  max-width: 30%;
  font-size: 1.125rem;
  margin-top: 10px;
  line-height: 1.5;
  text-align: center;
  color: #dddddd;
}

@media (max-width: 62.5rem){
  .sevendays-page p {
    max-width: 80%;
    font-size: 1.125rem;
    margin-top: 10px;
    line-height: 1.5;
    text-align: center;
    color: #dddddd;
  }
}

@media (max-width: 47.9375rem) {
  .sevendays-page {
    width: 90%;
  }
}
