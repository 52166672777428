.games-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: auto;
  width: auto;
  overflow: hidden;
  position: relative;
}

@font-face {
  font-family: 'MyWebFont';
  src: url('../Fonts/Bahnschrift.woff') format('woff'),
       url('../Fonts/BAHNSCHRIFT.TTF') format('truetype');
}

a {
  color: white;
  text-decoration: underline;
}

.games-background {
  position: fixed;
  opacity: 50%;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
  }
}

.games-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #161616;
  color: #ffffff;
  height: auto;
  overflow: hidden;
  animation: glow 1.5s ease-in-out infinite;
  z-index: 1;
}

.iframe-spotify-embed {
  padding-top: 20px;
}

.game-title {
  font-size: 1.15rem;
  text-align: left;
  width: 100%;
  font-family: 'MyWebFont', serif;
}

.game-from {
  margin-top: 5px;
  margin-left: 3px;
  font-size: 0.8rem;
  width: 100%;
  text-align: left;
}

.game-description {
  margin: 0;
  font-weight: 300;
  padding: 20px 20px;
  font-size: 0.9rem;
  width: 100%;
  text-align: left;
  font-family: 'MyWebFont', serif;
}

.grid-container {
  margin-top: 100px;
  row-gap: 20px;
  display: grid;
  column-gap: 20px;
  grid-template-columns: repeat(2, 1fr);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

@media (max-width: 62.5rem) {
  .grid-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 10px;
    column-gap: 20px;
    max-width: 90%;
  }
}

.grid-games {
  display: flex;
  width: 100%;
  max-width: 25rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.23);
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.grid-games:hover {
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.games-container {
  width: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.games-container h2 {
  font-size: 2rem;
  margin-top: 20px;
  text-align: center;
  color: #ffffff;
}

.games-container p {
  max-width: 100%;
  font-size: .8rem;
  margin-top: 10px;
  line-height: 1.5;
  text-align: center;
  color: #dddddd;
}

@media (max-width: 62.5rem){
  .games-container p {
    max-width: 80%;
    font-size: 1.125rem;
    display: flex;
  }
}

@media (max-width: 47.9375rem) {
  .games-container {
    width: 100%;
  }
}

@keyframes grow {
  from {
    transform: scale(0.95);
  }
  to {
    transform: scale(1);
  }
}
